<template>
    <div class="p-10 mx-auto">
        <p class="text-xl font-bold text-center"></p>
    </div>
</template>

<script>
export default {
    name: 'Home',
    data() {
        return {};
    },
    methods: {}
};
</script>

<style></style>
